<template>
    <header @mouseleave="menuHovered = null ; setLight(false)" @mouseover="!childrenItem && setLight(false)">
        <div id="logo" class="relative flex h-12 pt-4 mx-auto justify-center items-center">
            <router-link :to="{ name: 'home' }" class="flex items-center">
                <img src="/images/logo.png" class="flex-initial h-12 w-auto">
                <img src="/images/logo_type.png" class="flex-initial h-8 w-auto">
            </router-link>
            <svg class="md:hidden fill-mydrug-main absolute right-1 top-2 h-12 w-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" @click="openMenu = true">
                <path fill="none" d="M0 0h256v256H0z"/>
                <path d="M216 120H40a8 8 0 0 0 0 16h176a8 8 0 0 0 0-16ZM40 72h176a8 8 0 0 0 0-16H40a8 8 0 0 0 0 16ZM216 184H40a8 8 0 0 0 0 16h176a8 8 0 0 0 0-16Z"/>
            </svg>
        </div>
        <ul class="py-4 hidden md:flex justify-center">
            <li v-for="item in menu" :key="item.name" 
                @click="foldDropDown"
                @mouseover="hoverMenu(item)"
                class="mx-6 lg:mx-8 text-gray-700
                    md:text-lg lg:text-xl xl:text-lg 2xl:text-xl hover-underline"
                :class="[ menuHovered && item.name === menuHovered.name ? 'hovered-underline' : '' ]"
                >
                <router-link :to="{ name: item.link }" @click.prevent="item.name === '事業紹介'" class="flex flex-col items-center">
                    <span class="text-sm">{{item.eng}}</span>
                    {{item.name}}
                </router-link>
            </li>
        </ul>
        <transition 
            enter-class="opacity-0" enter-to-class="opacity-100" 
            enter-active-class="transition duration-300 ease-in-out"
            leave-class="opacity-100" leave-to-class="opacity-0" 
            leave-active-class="transition duration-300 ease-in-out"
        >
            <div class="absolute h-60 w-full bg-white" v-show="isDropdown">
                <section class="flex justify-center h-full items-center" v-show="isDropdown">
                    <img :src="'/images/header/' + hoveredChildImage" class="h-auto w-72 mr-8 object-cover shadow-md">
                    <div class="bg-cover h-48 w-auto mr-8"></div>
                    <ul>
                        <li v-for="item in childrenItem" :key="item.name" class="my-4 text-gray-700" @click="foldDropDown">
                            <router-link class="hover-underline" :to="{name: item.link}" @mouseover.native="hoveredChildImage = item.image" >{{item.name}}</router-link>
                        </li>
                    </ul>
                </section>
            </div>
        </transition>
        <div class="menu md:hidden fixed z-20 top-0 right-0 w-3/4 h-full bg-[#FCFCFC] transition flex items-center justify-center"
            :class="[ openMenu ? 'translate-x-0' : 'translate-x-[40rem]']"
        >
            <div @click="openMenu = false">
                <svg class="fill-white fixed right-1 top-2 h-12 w-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                    <rect width="256" height="256" fill="none"></rect>
                    <path d="M139.3,128l66.4-66.3a8.1,8.1,0,0,0-11.4-11.4L128,116.7,61.7,50.3A8.1,8.1,0,0,0,50.3,61.7L116.7,128,50.3,194.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L128,139.3l66.3,66.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4Z"></path>
                </svg>
            </div>
            <ul class="text-mydrug-text space-y-8 text-center text-lg font-bold">
                <router-link v-for="(item) in menu" :key="item.name" :to="{ name: item.link }" class="block hover:underline"><li @click="openMenu = false">{{item.name}}</li></router-link>
                <router-link :to="{ name: 'privacyPolicy' }" class="block hover:underline"><li @click="openMenu = false">プライバシーポリシー</li></router-link>
            </ul>
        </div>
        <div v-show="openMenu" class="transition fade-layer fixed z-10 top-0 right-0 w-full h-full bg-black"
            @click="openMenu = false"
            :class=" [openMenu ? 'opacity-50' : 'opacity-0'] "
        ></div>
    </header>
</template>

<script>
    export default {
        name: "TopHeader",
        data() {
            return {
                menu: [
                    {
                        name: "ホーム",
                        eng: "home",
                        link: "home"
                    },
                    {
                        name: "会社概要",
                        eng: "company",
                        link: "company"
                    },
                    {
                        name: "新着情報",
                        eng: "news",
                        link: "news"
                    },
                    {
                        name: "事業紹介",
                        eng: "business",
                        link: "business",
                        children: [
                            {name: "医療通訳コーディネーター", image: "medicalBeauty.jpg", link: "medicalBeauty"},
                            {name: "通信販売", image: "ec.jpg", link: "ec"},
                            {name: "ドラッグストア", image: "drugStore.jpg", link: "drugStore"},
                            {name: "家電OEM", image: "oem.jpg", link: "oem"},
                        ]
                    },
                    {
                        name: "採用情報",
                        eng: "recruit",
                        link: "recruit"
                    },
                    {
                        name: "お問い合わせ",
                        eng: "contact",
                        link: "contact"
                    },
                ],
                menuHovered: null,
                hoveredChildImage: "medicalBeauty.jpg",
                isDropdown: false,
                openMenu: false,
            }
        },
        computed: {
            childrenItem(){
                if (!this.menuHovered || !this.menuHovered.children) {return null}
                const result = this.menu.filter((e) => {
                    return e.name === this.menuHovered.name
                });
                return result[0].children
            }
        },
        watch: {
            childrenItem(val){
                this.isDropdown = val ? true : false
            }
        },
        methods: {
            hoverMenu(item){
                this.menuHovered = item
                if (this.childrenItem) {
                    this.setLight(true)
                }
            },
            setLight(bool){
                this.$emit("setLight", bool)
            },
            foldDropDown(){
                this.isDropdown = false
                this.setLight(false)
            }
        },
    }
</script>

<style scoped>

</style>
