import Vue from 'vue'
import VueBreadcrumbs from 'vue-2-breadcrumbs';
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'

Vue.config.productionTip = false
Vue.use(VueBreadcrumbs);
Vue.directive("my-scroll", {
  bind(el){
    el.style.opacity = "0"
  },
  inserted(el, binding){
    // アニメーション名を変数に格納する
    const animationType = binding.value.type

    function handleScroll(el, binding){
      // 順番に現れるようにするために、アイテムのインデックスを活用している
      // ディレクティブで指定しない場合、遅延0でアニメーションを実行
      const delay = binding.value.delay || 0

      // トリガー発動の高さ。
      const triggerHeight = window.innerHeight * .9;
      // エレメントの最上からブラウザのトップまでの距離
      const elHeight = el.getBoundingClientRect().top;

      if (triggerHeight > elHeight) {
          // delay * 100 ms後にアニメーションクラスを追加する
          el.style.animationDelay = `${delay * 80}` + "ms"
          el.classList.add("animate__animated", animationType)
      }
      return triggerHeight > elHeight
    }

    // アニメーションが再生し終わるとクラスを削除する
    function handleAnimationEnd() {
      el.classList.remove("animate__animated", animationType)
    }

    function f() {
      if (handleScroll(el, binding)) {
        el.style.opacity = "1"
        // 指定の発火の高さに到達しアニメーションが再生されたらリスナーを解除する
        window.removeEventListener("scroll", f)
      }
    }
    // 全Windowでスクロールイベントをリッスンする
    window.addEventListener("scroll", f)
    el.addEventListener('animationend', handleAnimationEnd, {once: true});
  }
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
