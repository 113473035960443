import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: { breadcrumb: 'ホーム' }
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('../views/AboutCompany.vue'),
    meta: {
      breadcrumb: {
        label: "会社概要",
        parent: "home"
      }
    }
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/NewsView.vue'),
    meta: {
      breadcrumb: {
        label: "新着情報",
        parent: "home"
      }
    }
  },
  {
    path: '/news/:id',
    name: 'newsDetail',
    component: () => import('../views/NewsDetail.vue'),
    meta: {
      breadcrumb: {
        parent: "news"
      }
    }
  },
  {
    path: '/business',
    name: 'business',
    component: () => import('../views/BusinessView.vue'),
    meta: {
      breadcrumb: {
        label: "事業紹介",
        parent: "home"
      }
    }
  },
  {
    path: '/business/medical_beauty',
    name: 'medicalBeauty',
    component: () => import('../views/MedicalBeauty.vue'),
    meta: {
      breadcrumb: {
        label: "医療通訳コーディネーター",
        parent: "business"
      }
    }
  },
  {
    path: '/business/drug_store',
    name: 'drugStore',
    component: () => import('../views/DrugStore.vue'),
    meta: {
      breadcrumb: {
        label: "ドラッグストア事業",
        parent: "business"
      }
    }
  },
  {
    path: '/busines/ec',
    name: 'ec',
    component: () => import('../views/EC.vue'),
    meta: {
      breadcrumb: {
        label: "通信販売事業",
        parent: "business"
      }
    }
  },
  {
    path: '/busines/oem',
    name: 'oem',
    component: () => import('../views/OEM.vue'),
    meta: {
      breadcrumb: {
        label: "家電OEM事業",
        parent: "business"
      }
    }
  },
  {
    path: '/recruit',
    name: 'recruit',
    component: () => import('../views/RecruitView.vue'),
    meta: {
      breadcrumb: {
        label: "採用情報",
        parent: "home"
      }
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue'),
    meta: {
      breadcrumb: {
        label: "お問い合わせ",
        parent: "home"
      }
    }
  },
  {
    path: '/privacy_policy',
    name: 'privacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue'),
    meta: {
      breadcrumb: {
        label: "プライバシーポリシー",
        parent: "home"
      }
    }
  },
]

const scrollBehavior = () => {
  return { x: 0, y: 0 }
}

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior
})

export default router
