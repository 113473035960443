<template>
    <div id="app" class="font-sans relative bg-white">
        <TopHeader @setLight="setLight" class="relative z-40"/>
        <div class="relative mt-8">
            <transition enter-active-class="animate-fadeIn" leave-active-class="animate-fadeOut" mode="out-in">
                <router-view :hoveredHeader="hoveredHeader" class="relative z-20 transition duration-500 ease-in-out backdrop-blur rounded-2xl"/>
            </transition>
            <TheFooter class="relative z-20"/>
            <MoovingShape class="absolute z-10 bottom-0 left-0"/>
            <div v-show="hoveredHeader" class="fade-layer absolute left-0 top-0 w-full h-full bg-black opacity-50 z-30"></div>
        </div>
    </div>
</template>

<script>
    import TopHeader from './components/TopHeader.vue'
    import TheFooter from './components/TheFooter.vue'
    import MoovingShape from './components/MoovingShape.vue'
    export default {
        name: "App",
        components: { TopHeader, TheFooter, MoovingShape },
        data() {
            return {
                hoveredHeader: false,
            }
        },
        methods: {
            setLight(ags){
                this.hoveredHeader = ags
            }
        },
    }
</script>

<style>
    .breadcrumb {
        display: flex;
        flex-wrap: wrap;
        margin: 16px 4px;
        list-style: none;
        color: rgb(55 65 81);
    }
    .breadcrumb-item + .breadcrumb-item {
        padding-left: 6px;
    }
    .breadcrumb-item + .breadcrumb-item::before {
        display: inline-block; 
        content: '/';
    }
    .breadcrumb-item a {
        font-weight: bold;
        text-decoration: none;
        transition: 0.3s;
    }
    .breadcrumb-item a:hover {
        text-decoration: underline;
        color: #00aef2;
    }
</style>
