<template>
    <footer class="px-4 sm:px-12 md:px-16 lg:px-32 xl:px-44 mt-16 pt-4 backdrop-blur shadow-xl border-t border-white">
        <div class="footer-container flex flex-col lg:flex-row justify-start text-mydrug-text">
            <div id="logo" class="relative flex h-12 mt-4 mx-auto justify-start items-center pb-8">
                <img src="/images/logo.png" class="flex-initial h-12 w-auto">
                <img src="/images/logo_type.png" class="flex-initial h-8 w-auto">
            </div>
            <span class="grow space-y-2"></span>
            <div class="mt-4 lg:mt-0 pt-2 lg:pt-0 text-base lg:flex lg:justify-end bg-[#FCFCFC] lg:bg-transparent rounded-md shadow-md lg:shadow-none text-center">
                <ul class="space-y-1 mb-1">
                    <router-link :to="{ name: 'company' }" class="p-2 rounded-md lg:bg-transparent block hover:underline"><li @click="scrollToTop">会社概要</li></router-link>
                    <router-link :to="{ name: 'business' }" class="p-2 rounded-md lg:bg-transparent block hover:underline"><li @click="scrollToTop">事業紹介</li></router-link>
                    <router-link :to="{ name: 'news' }" class="p-2 rounded-md  lg:bg-transparent block hover:underline"><li @click="scrollToTop">新着情報</li></router-link>
                </ul>
                <ul class="lg:ml-8 space-y-1 mb-1">
                    <router-link :to="{ name: 'recruit' }" class="p-2 rounded-md lg:bg-transparent block hover:underline"><li @click="scrollToTop">採用情報</li></router-link>
                    <router-link :to="{ name: 'contact' }" class="p-2 rounded-md lg:bg-transparent block hover:underline"><li @click="scrollToTop">お問い合わせ</li></router-link>
                </ul>
                <ul class="space-y-1">
                    <router-link :to="{ name: 'privacyPolicy' }" class="p-2 rounded-md lg:bg-transparent block hover:underline"><li @click="scrollToTop">プライバシーポリシー</li></router-link>
                </ul>
            </div>
        </div>
        <p class="text-sm lg:text-base mt-8 pb-8 text-center text-[#BEC6C2]">COPYRIGHT© MAIDORA CO.LTD, ALL RIGHTS RESERVED.</p>
    </footer>
</template>

<script>
    export default {
        name: "TheFooter",
        methods: {
            scrollToTop(){
                window.scroll({
                    top: 0,
                    behavior: "smooth"
                })
            }
        },
    }
</script>

<style scoped>
    footer {
        box-shadow: 0px 22px 19px 29px rgba(182, 214, 234, 0.3);
    }
</style>
